<template>
<div id="chat">
  <div class="chat-container" @click="closeEmo">
    <div class="chat-wrap">
      <div class="chat-user">
        <div class="cu-title">
          <span>联系人列表</span>
        </div>
        <div class="user-list">
          <div class="user-item" :class="{activeChat:chatIndex===index}" v-for="(item,index) in chatList" :key="index" @click="selectChat(item,index)">
            <div class="figure">
              <img :src="item.headImage" />
              <span class="notice-badge" v-show="item.obtainUnreadNum>0">{{item.obtainUnreadNum}}</span>
            </div>
            <div class="baseName">
              <p>
                <span>{{item.nickName}}</span>
                <label v-if="userInfo.type==2">{{item.expectedPositionName}}</label>
                <label v-if="userInfo.type==1"><strong>{{item.enterpriseName}}-</strong>{{item.position}}</label>
              </p>
              <em>{{item.latestNewsTime}}</em>
              <div class="msg-tips" v-show="item.type==0">{{item.latestNews}}</div>
              <div class="msg-tips" v-show="item.type==1">图片消息</div>
              <div class="msg-tips" v-show="item.type==2">文件消息</div>
              <div class="msg-tips" v-show="item.type==3">语音消息</div>
              <div class="msg-tips" v-show="item.type==4">交换手机号</div>
              <div class="msg-tips" v-show="item.type==5">交换微信号</div>
              <div class="msg-tips" v-show="item.type==6">面试邀请</div>
              <div class="msg-tips" v-show="item.type==7">请求简历</div>
              <div class="delChat" @click.stop="delChat(item.id)"><i class="el-icon-delete"></i></div>
            </div>

          </div>
        </div>
      </div>
      <div class="chat-conversation" v-show="chatInfo.id">
        <div class="top-box" v-if="userInfo.type==1">
          <div class="top-info">
            <span>{{positionInfo.userName}}</span>
            <span>{{positionInfo.enterpriseName}}<i class="vline"></i>{{positionInfo.position}}</span>
            <label @click="jumpJob">查看职位详情</label>
          </div>
          <div class="chat-position" @click="jumpJob">
            <span>{{positionInfo.positionName}}</span>
            <label>
                <em>
                  {{positionInfo.minimumWage}}<i v-show="positionInfo.maximumSalary">-{{positionInfo.maximumSalary}}</i>
                </em>
            </label>
            <span>{{positionInfo.areaName}}</span>
          </div>
        </div>
        <div v-else  class="top-box">
          <div class="top-info">
            <span>{{resumeInfo.recruitUserInfo.userName}}</span>
            <span>{{resumeInfo.resumeBasicInfo.educationName}}<i class="vline"></i>{{resumeInfo.resumeBasicInfo.workExperienceName}}</span>
            <label @click="jumpResume">查看在线简历</label>
          </div>
          <div class="chat-position" @click="jumpResume">
            <span>{{resumeInfo.resumeJobDesire.expectedPositionName}}</span>
            <label>
                <em >
                  {{resumeInfo.resumeJobDesire.minimumWage}}-{{resumeInfo.resumeJobDesire.maximumSalary}}
                </em>
            </label>
            <span>{{resumeInfo.resumeBasicInfo.areaName}}</span>
          </div>
        </div>
        <div class="chat-record">
          <div class="msg-item" v-for="(item,index) in msgList" :key="index" :id="'record'+index">
            <div class="msg-left" v-show="item.sendId!=userInfo.id">
               <div class="chatTime">{{item.sendTime}}</div>
               <img class="avator" :src="item.sendPic" />
               <p v-show="item.type==0" v-html="item.content"></p>
               <p v-show="item.type==1">
                  <img class="chat-pic" :src="item.content" />
               </p>
               <p v-show="item.type==2" @click="downFile(item)">
                <span class="resumeName">
                  <img src="@/assets/imgs/personal/word.png" />
                  <label>{{item.resumeName}}</label>
                </span>
               </p>
               <p v-show="item.type==3">
                 <audio controls :src="item.content"></audio> 
               </p>
              <div class="exchangeWxLeft" v-show="item.type==4">
                 <img src="@/assets/imgs/ic_phone_paused.png" />
                 <h4>我想要和您交换手机号，您是否同意</h4>
                 <div class="exchange-btn" v-if="item.exchangeStatus==0">
                   <span @click="setExchange(item,2)">拒绝</span>
                   <span @click="setExchange(item,1)">同意</span>
                 </div>
                 <div wx:else class="exchange-disabled" v-else>
                   <span>拒绝</span>
                   <span>同意</span>
                 </div>
                 <div class="copy-box" v-show="item.exchangeStatus==1">
                   <span>{{chatInfo.nickName}}的手机号：{{item.content}}</span>
                   <div class="copyBtn" @click="copyWx(item.content)">复制手机号</div>
                 </div>
              </div>
               <div class="exchangeWxLeft" v-show="item.type==5">
                 <img src="@/assets/imgs/wxBtn.png" />
                 <h4>我想要和您交换微信，您是否同意</h4>
                 <div class="exchange-btn" v-if="item.exchangeStatus==0">
                   <span @click="setExchange(item,2)">拒绝</span>
                   <span @click="setExchange(item,1)">同意</span>
                 </div>
                 <div wx:else class="exchange-disabled" v-else>
                   <span>拒绝</span>
                   <span>同意</span>
                 </div>
                 <div class="copy-box" v-show="item.exchangeStatus==1">
                   <span>{{chatInfo.nickName}}的微信号：{{item.content}}</span>
                   <div class="copyBtn" @click="copyWx(item.content)">复制微信号</div>
                 </div>
              </div>
              <div class="interview-left exchangeWxLeft"  v-show="item.type==6">
                <img src="@/assets/imgs/company/company-logo.png" />
                <h4>{{chatInfo.enterpriseName}}向您发送了面试邀请,点击查看</h4>
                <div class="interviewBtn" @click="viewInter(item)">点击查看</div>
              </div>
              <div class="exchangeWxLeft" v-show="item.type==7">
                 <img style="width:22px" src="@/assets/imgs/company/c-resume.png" />
                 <h4>我想要和您的一份附件简历，您是否同意</h4>
                 <div class="exchange-btn" v-if="item.exchangeStatus==0">
                   <span @click="setExchangeResume(item,2)">拒绝</span>
                   <span @click="setExchangeResume(item,1)">同意</span>
                 </div>
                 <div wx:else class="exchange-disabled" v-else>
                   <span>拒绝</span>
                   <span>同意</span>
                 </div>
              </div>
            </div>
            <div class="msg-right" v-show="item.sendId==userInfo.id">
              <div class="chatTime">{{item.sendTime}}</div>
              <div class="msg-right-wrap">
                <p v-show="item.type==0" v-html="item.content"></p>
                <p v-show="item.type==1">
                    <img class="chat-pic" :src="item.content" />
                </p>
                <p v-show="item.type==2" @click="downFile(item)">
                    <span class="resumeName">
                      <img src="@/assets/imgs/personal/word.png" />
                      <label>{{item.resumeName}}</label>
                    </span>
                </p>
                <p v-show="item.type==3">
                  <audio controls :src="item.content"></audio> 
                </p>
                <div class="exchangeWX-text" v-show="item.type==4">
                    <img src="@/assets/imgs/ic_phone_paused.png" />
                    <span>已发送手机号交换请求</span>
                 </div>
                 <div class="exchangeWX-text" v-show="item.type==5">
                    <img src="@/assets/imgs/wxBtn.png" >
                    <span>已发送微信交换请求</span>
                 </div>
                 <div class="exchangeWX-text" v-show="item.type==6">
                    <img src="@/assets/imgs/company/c-interview.png" >
                    <span>发送了面试邀请</span>
                 </div>
                <div class="exchangeWX-text" v-show="item.type==7">
                    <img style="width:22px" src="@/assets/imgs/company/c-resume.png" >
                    <span>已发送简历请求</span>
                 </div>
                <img class="avator" :src="item.sendPic" />
               </div>
            </div>
          </div>
        </div>
        <!-- 输入框 -->
        <div class="send-box">
          <div class="send-top">
            <img width="25px" @click.stop="openEmo" height="24px" src="@/assets/imgs/company/looks.png" />
            <el-upload
              class="picture-uploader"
              :on-success="pictureSuccess" :action="baseUrl" :show-file-list="false"
            >
              <img width="27px" height="24px" src="@/assets/imgs/company/picture.png"  class="avatar">
            </el-upload>
            <i class="line"></i>
            <div class="toolbar-btn" v-show="userInfo.type==1" @click="sendResume">发送简历</div>
            <div class="toolbar-btn" @click="sendWx">微信号</div>
            <div class="toolbar-btn" @click="sendPhone">手机号</div>
            <div class="toolbar-btn" v-show="userInfo.type==2" @click="requestResume">求简历</div>
            <div class="toolbar-btn" v-show="userInfo.type==2" @click="sendInvite">邀请面试</div>
          </div>
          <!-- 表情 -->
          <div class="emoj" v-show="showEmo">
            <span @click="selectEmoj(index)"  v-for="(item,index) in emoTextList" :key="index" v-html="textToImg(item)"></span>
          </div>
          <div class="send-footer">
            <el-input type="textarea" placeholder="聊一聊" v-model="content">
            </el-input>
            <div class="send-btn" @click="sendText">发送</div>
          </div>
        </div>
      </div>
      <div class="chat-conversation" v-show="!chatInfo.id">
        <div class="noMsg">
          <div class="noMsg-pic">
            <img src="@/assets/imgs/personal/icon-welcome.png" />
            <span>与您进行过沟通的 好友 都会在左侧列表中显示</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <resume-detail :resumeDialog="resumeDialog" :userId="userId" @closeDialog="closeDialog"></resume-detail>
  <div class="interviewBox">
    <el-dialog
      title="面试邀请"
      :visible.sync="interDialog"
      width="500px"
    >
      <div class="interview">
        <p>
          <span>职 位</span><label>{{interviewInfo.positionInfoName}}</label>
        </p>
        <p>
          <span>时 间</span><label>{{interviewInfo.interviewTime}}</label>
        </p>
        <p>
          <span>面试地址</span><label>{{interviewInfo.interviewLocation}}{{interviewInfo.houseNumber}}</label>
        </p>
        <p>
          <span>联系人</span><label>{{interviewInfo.inviteeName}}~{{interviewInfo.contactsPhone}}</label>
         </p>
         <p>
          <span>备 注</span><label>{{interviewInfo.remark}}</label>
         </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-show="msgInfo.exchangeStatus==1" :disabled="true">已同意邀请</el-button>
        <el-button v-show="msgInfo.exchangeStatus==2" :disabled="true">已拒绝邀请</el-button>
        <el-button v-show="msgInfo.exchangeStatus==0" @click="agreeInterview(2)">拒绝邀请</el-button>
        <el-button v-show="msgInfo.exchangeStatus==0" type="primary" @click="agreeInterview(1)">同意邀请</el-button>
      </span>
    </el-dialog>
  </div>
  <!-- 面试邀请 -->
  <div class="interviewBox">
      <el-dialog
      title="面试邀请"
      :visible.sync="inviteDialog"
      width="500px"
    >
      <div class="inviteForm">
        <el-form :model="inviteModel" :rules="inviteRules" ref="inviteForm" label-width="110px" class="demo-ruleForm">
          <el-form-item label="面试职位" prop="positionInfoId">
              <el-select v-model="inviteModel.positionInfoId" @change="selectPosition" placeholder="请选择">
                <el-option
                  v-for="item in publishList"
                  :key="item.id"
                  :label="item.positionName"
                  :value="item.id">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="面试地点" prop="interviewLocation">
            <div @click="openAddressDialog" class="interviewLocation">{{inviteModel.interviewLocation}}{{inviteModel.houseNumber}}</div>
         </el-form-item>
         <el-form-item label="面试时间" prop="interviewTime">
           <el-date-picker
              v-model="inviteModel.interviewTime"
              type="datetime"
              placeholder="请选择面试时间"
              align="right"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
            >
          </el-date-picker>
         </el-form-item>
         <el-form-item label="联系人" prop="contactsName">
           <el-input v-model="inviteModel.contactsName" placeholder="请输入联系人"></el-input>
         </el-form-item>
         <el-form-item label="联系人手机号" prop="contactsPhone">
           <el-input v-model="inviteModel.contactsPhone" placeholder="请输入联系人手机号"></el-input>
         </el-form-item>
         <el-form-item label="备注" >
           <el-input type="textarea" v-model="inviteModel.remark" placeholder="请输入备注"></el-input>
         </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sendInterview()">发送面试邀请</el-button>
      </span>
    </el-dialog>
  </div>
  <!-- 面试地点-->
   <div class="interviewBox">
      <el-dialog
      title="面试地点"
      :visible.sync="addressDialog"
      width="500px"
      >
      <div class="inviteForm">
        <el-form :model="addressModel" :rules="addressRules" ref="addressForm" label-width="110px" class="demo-ruleForm">
          <el-form-item label="面试地点" prop="interviewLocation">
             <el-input v-model="addressModel.interviewLocation" placeholder="请输入面试地点"></el-input>
          </el-form-item>
          <el-form-item label="门牌号" prop="houseNumber">
             <el-input v-model="addressModel.houseNumber" placeholder="请输入门牌号"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="address-footer">
        <el-button @click="addressDialog=false">取消保存</el-button>
        <el-button type="primary" @click="saveAddress()">保存地址</el-button>
      </span>
    </el-dialog>
  </div>
</div>
</template>

<script>
import resumeDetail from './resume/resumeDetail.vue'
import { parseTime } from "@/utils/index.js"
import {addInterviewInvitation,getEnterprisePositionList,getChatRecordResumeDetails,setExchange, getMessageList,verifyMobileWeChatResume,removalMessage,getResumeInfo,addFriend,getJobDetails,getChatHistory,sendMsg,setReadMessage} from "@/api/index.js";
export default {
  name: "chat",
  components: {
    'resume-detail': resumeDetail,
  },
  data() {
    return {
      userInfo:{},
      content: '',
      emoTextList: ['微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭', '尴尬', '发怒', '调皮', '呲牙', '惊讶', '难过', '酷', '冷汗', '抓狂', '吐', '偷笑', '可爱', '白眼', '傲慢', '饥饿', '困', '惊恐', '流汗', '憨笑', '大兵', '奋斗', '咒骂', '疑问', '嘘', '晕', '折磨', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻', '鼓掌', '糗大了', '坏笑', '左哼哼', '右哼哼', '哈欠', '鄙视', '委屈', '快哭了', '阴险', '亲亲', '吓', '可怜', '菜刀', '西瓜', '啤酒', '篮球', '乒乓', '咖啡', '饭', '猪头', '玫瑰', '凋谢', '示爱', '爱心', '心碎', '蛋糕', '闪电', '炸弹', '刀', '足球', '瓢虫', '便便', '月亮', '太阳', '礼物', '拥抱', '强', '弱', '握手', '胜利', '抱拳', '勾引', '拳头', '差劲', '爱你', 'NO', 'OK', '爱情', '飞吻', '跳跳', '发抖', '怄火', '转圈', '磕头', '回头', '跳绳', '挥手', '激动', '街舞', '献吻', '左太极', '右太极'],
      showEmo:false,
      chatList:[],
      chatInfo:{},
      chatIndex:'',
      positionInfo:{},
      msgList:[],
      type:0,
      baseUrl:'',
      recvId:'',
      positionId:'',
      resumeInfo:{
        recruitUserInfo:{},
        resumeBasicInfo:{},
        resumeJobDesire:{}
      },
      resumeDialog:false,
      userId:'',
      resumeName:'',
      interDialog:false,
      inviteDialog:false,
      msgInfo:{},
      interviewInfo:{},
      inviteModel:{
        contactsName: "",
        contactsPhone: "",
        houseNumber: "",
        interviewLocation: "",
        interviewTime: "",
        mapLatitude: "",
        mapLongitude: "",
        positionInfoId: '',
        recvId: '',
        remark: ""
      },
      addressModel:{
        houseNumber: "",
        interviewLocation: "",
        interviewTime: "",
        mapLatitude: "",
      },
      inviteRules:{
         positionInfoId: [
            { required: true, message: '请选择面试职位', trigger: 'change' },
        ],
        contactsName: [
            { required: true, message: '请输入联系人', trigger: 'change' },
         ],
         contactsPhone: [
            { required: true, message: '请输入联系人手机号', trigger: 'change' },
         ],
         interviewLocation: [
            { required: true, message: '请选择面试地点', trigger: 'change' },
         ],
         interviewTime: [
            { required: true, message: '请选择面试时间', trigger: 'change' },
         ],
      },
      addressRules:{
         interviewLocation: [
            { required: true, message: '请输入面试地点', trigger: 'blur' },
        ],
        houseNumber: [
            { required: true, message: '请输入门牌号', trigger: 'blur' },
         ]
      },
      publishList:[],
      addressDialog:false
    } 
  },
  mounted(){
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.initWebSocket()
    if(this.$route.query.recvId){
      this.recvId = this.$route.query.recvId
      this.positionId = this.$route.query.positionId
      this.addFriend()
    }else{
       this.getMessageList()
    }
    this.baseUrl = process.env.VUE_APP_BASE_API + '/webApi/common/upload'
  },
  methods: {
    requestResume(){  //求简历
      this.type = 7
      this.content = '请求简历'
      this.send()
    },
    saveAddress(){
        this.$refs['addressForm'].validate((valid) => {
          if (valid) {
             this.addressDialog= false
             this.inviteModel.houseNumber = this.addressModel.houseNumber
             this.inviteModel.interviewLocation = this.addressModel.interviewLocation
          } 
        });
    },
    sendInterview(){
      addInterviewInvitation({
        contactsName: this.inviteModel.contactsName,
        contactsPhone: this.inviteModel.contactsPhone,
        houseNumber: this.inviteModel.houseNumber,
        interviewLocation: this.inviteModel.interviewLocation,
        interviewTime: this.inviteModel.interviewTime,
        mapLatitude: this.inviteModel.mapLatitude,
        mapLongitude: this.inviteModel.mapLongitude,
        positionInfoId: this.inviteModel.positionInfoId,
        recvId: this.chatInfo.id,
        remark: this.inviteModel.remark,
      }).then(res=>{
        this.inviteDialog = false
        if(res.code==200){
          this.$message({
            message:'邀请成功',
            type: 'success'
          });
          this.getChatHistory()
        }else{
          this.$message({
            message:res.msg,
            type: 'warning'
          });
        }
      })
    },
    openAddressDialog(){
      this.addressDialog = true
    },
    selectPosition(index){
      this.publishList.forEach(item=>{
        if(item.id == index){
          this.inviteModel.houseNumber = item.houseNumber
          this.inviteModel.interviewLocation = item.workAddress
          this.inviteModel.contactsName = item.publisherName
          this.inviteModel.contactsPhone = this.userInfo.phone
        }
      })
    },
    sendInvite(){
      this.inviteDialog = true
      this.getEnterprisePositionList()
    },
    getEnterprisePositionList(){
      getEnterprisePositionList().then(res=>{
        this.publishList = res.rows
        this.inviteModel.positionInfoId = this.chatInfo.positionInfoId
        this.selectPosition(this.chatInfo.positionInfoId)
      })
    },
    agreeInterview(index){
       setExchange({
         id:this.msgInfo.id,
         exchangeStatus:index
      }).then(res=>{
        this.interDialog = false
         if(res.code==200){
          if(index==1){
            this.getChatHistory()
            this.$message({
              message:'已同意邀请',
              type: 'success'
            });
          }
          if(index==2){
            this.getChatHistory()
            this.$message({
              message:'已拒绝邀请',
              type: 'success'
            });
          }
        }else{
          this.$message({
            message:res.msg,
            type: 'warning'
          });
        }
      })
    },
    viewInter(item){
      this.interDialog = true
      this.msgInfo = item
      getChatRecordResumeDetails({privateMessageId:item.id}).then(res=>{
        this.interviewInfo = res.data
      })
    },
    setExchangeResume(item,index){
       setExchange({
         id:item.id,
         exchangeStatus:index
      }).then(res=>{
        if(res.code==200){
          if(index==1){
            this.$message({
              message:'已同意',
              type: 'success'
            });
            this.sendResume()
          }
          if(index==2){
            this.getChatHistory()
            this.$message({
              message:'已拒绝',
              type: 'success'
            });
          }
        }else{
          this.$message({
            message:res.msg,
            type: 'warning'
          });
        }
      })
    },
    setExchange(item,index){
      setExchange({
         id:item.id,
         exchangeStatus:index
      }).then(res=>{
        if(res.code==200){
          if(index==1){
            this.getChatHistory()
            this.$message({
              message:'交换成功',
              type: 'success'
            });
          }
          if(index==2){
            this.getChatHistory()
            this.$message({
              message:'已拒绝',
              type: 'success'
            });
          }
        }else{
          this.$message({
            message:res.msg,
            type: 'warning'
          });
        }
      })
    },
    copyWx(val){
       this.$copyText(val)
        this.$message({
          message:'复制成功',
          type: 'success'
        });
    },
    sendPhone(){
       verifyMobileWeChatResume({
        friendId:this.chatInfo.id
      }).then(res=>{
        if(res.data.sendOrNot){
          this.content = res.data.phone;
          this.type = 4
          this.send()
        }else{
          this.$message({
            message: '对方回复后方可使用',
            type: 'warning'
          });
        }
      })
    },
    sendWx(){
      verifyMobileWeChatResume({
        friendId:this.chatInfo.id
      }).then(res=>{
        var info = res.data
        if(info.sendOrNot){
           if(!info.wechatNum){
             this.$message({
              message: '请添加微信号',
              type: 'warning'
            });
           }else{
            this.content = info.wechatNum;
            this.type =5
            this.send()
           }
        }else{
          this.$message({
            message: '对方回复后方可使用',
            type: 'warning'
          });
        }
      })
    },
    downFile(item){
      window.location.href = item.content
      console.log(item)
    },
    sendResume(){
      verifyMobileWeChatResume({
        friendId:this.chatInfo.id
      }).then(res=>{
        var info = res.data
        if(info.sendOrNot){
           if(!info.attachmentResumeUrl){
             this.$message({
              message: '请上传简历',
              type: 'warning'
            });
           }else{
            this.content = info.attachmentResumeUrl;
            this.type = 2
            this.resumeName = info.resumeName
            this.send()
           }
        }else{
          this.$message({
            message: '对方回复后方可使用',
            type: 'warning'
          });
        }
      })
    },
    jumpJob(){
      this.$router.push({
        path:'/jobDetail',
        query:{
          positionId: this.positionInfo.id
        }
      })
    },
    delChat(id){
      removalMessage(id).then((res)=>{
        if(res.code==200){
           this.$message({
             message: '删除成功！',
             type: 'success'
           });
          if(id==this.recvId){
            this.$router.push({
              path:'chat',query:{
                recvId:''
              }
            })
          }
          this.getMessageList()
          this.chatInfo = {}
        }
      })
    },
    closeDialog() {
      this.resumeDialog = false
    },
    jumpResume() {
      this.resumeDialog = true
      this.userId = this.resumeInfo.recruitUserInfo.id
    },
    addFriend(){
       addFriend({
         friendId:this.recvId,
         positionInfoId:this.positionId
       }).then(()=>{
           this.getMessageList()
       })
    },
    setReadMessage(){
      setReadMessage({
        sendId:this.chatInfo.id
      }).then(()=>{
        getMessageList().then(res=>{
          this.chatList = res.rows
        })
      })
    },
    getMessageList(){
      getMessageList().then(res=>{
        this.chatList = res.rows

        this.chatList.forEach((s,index)=>{
          if(s.id == this.recvId){
            this.chatInfo = s
            console.log( this.chatInfo)
            this.chatIndex = index
            this.userInfo.type==1?this.getJobDetails():this.getResumeInfo()
            this.getChatHistory()
          }
          
        })
      })
    },
    selectChat(item,index){
      this.chatIndex = index
      this.chatInfo = item
      console.log('dsssss')
      this.userInfo.type==1?this.getJobDetails():this.getResumeInfo()
      this.getChatHistory()
      this.setReadMessage()
    },
    //获取简历信息
    getResumeInfo() {
      getResumeInfo({
        userInfoId: this.chatInfo.id
      }).then(res => {
        var info = res.data
        if (info.recruitUserInfo.dateOfBirth) {
          info.recruitUserInfo.age = new Date().getFullYear() - info.recruitUserInfo.dateOfBirth.split('-')[0]
        }
        this.resumeInfo = info
      })
    },
    //获取职位信息
    getJobDetails(){
      console.log(this.chatInfo.positionInfoId,'dddddddddd')
      getJobDetails({
         positionInfoId:this.chatInfo.positionInfoId
      }).then(res=>{
        this.positionInfo = res.data
      })
    },
    initWebSocket(){
      var _this = this
      var userInfo =  JSON.parse(localStorage.getItem('userInfo'))
        //心跳设置
        var data = {
           cmd:1,
           data: {
              userId: userInfo.id
            }
        }
      var heartCheck = {
          timeoutObj: null, 
          start(){
            console.log()
            SocketTask.send(JSON.stringify(data))
          },
           reset(){
            clearTimeout(heartCheck.timeoutObj);
            heartCheck.timeoutObj = setTimeout(function() {
              heartCheck.start();
            }, 10000);
          }
       }
      //  var SocketTask = new WebSocket("wss://www.hmbhrc.com:8089/im")
      var SocketTask = new WebSocket(process.env.VUE_APP_BASE_WS)
      SocketTask.onopen = function () {
          heartCheck.start();
      }
      SocketTask.onmessage = function (evt) {
        let sendInfo = JSON.parse(evt.data)
        if(sendInfo.cmd==3){
          _this.getChatHistory()
        }
        heartCheck.reset();  
      }
      SocketTask.onclose = function () {
          console.log("连接已关闭...")
      }
    },
    getChatHistory(){
       getChatHistory({
         page:1,
         size:100,
         friendId:this.chatInfo.id
       }).then(res=>{
         var ids = 'record' + (res.rows.length-1)
 
         this.msgList = res.rows 
         
         if(res.total>0) {
            this.$nextTick(()=>{
                document.getElementById(ids).scrollIntoView()
            })
         }
         let textToImg = (emoText) => {
            let word = emoText.replace(/;/gi, '');
            let idx = this.emoTextList.indexOf(word);
             let url = require(`@/assets/imgs/emoji/${idx}.gif`);
            return `<img src="${url}" />`
         }
         this.msgList.forEach(item=>{
           if(item.type==0){
              item.content = item.content.replace(/[\u4E00-\u9FA5]{1,3};/gi,textToImg)
            }
            item.sendTime = parseTime(item.sendTime,'{m}-{d} {h}:{i}')
         })
      
       })
    },
    selectEmoj(index){
       this.content = this.content+this.emoTextList[index]+';'
    },
    textToImg(emoText) {
      let idx = this.emoTextList.indexOf(emoText);
      let url = require(`@/assets/imgs/emoji/${idx}.gif`);
      return `<img src="${url}" />`
    },
    openEmo(){
      this.showEmo? this.showEmo=false: this.showEmo=true
    },
    closeEmo(){
      this.showEmo =false
    },
   pictureSuccess(res){
    this.content = res.url
    this.type = 1 ;
    this.send()
   },
    sendText(){
      this.type = 0 ;
      this.send()
    },
    //发送数据
    send(){
      sendMsg({
        content:this.content,
        recvId:this.chatInfo.id,
        type:this.type,
        resumeName:this.resumeName
      }).then(res=>{
        if(res.code==200){
          this.content = ''
          this.getChatHistory()
        }else{
           this.$message({
            message:res.msg,
            type: 'warning'
          });
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
#chat {
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
}

.chat-container {
  width: 1200px;
  overflow: hidden;
  margin: 0 auto;
  min-height: calc(100vh - 70px);
}

.chat-wrap {
  position: absolute;
  top:130px;
  bottom: 20px;
}
.activeChat{
  background: #f2f2f2;
}
.delChat{
  position:absolute;
  top: 32px;
  right: -8px;
  color: #b3b3b3;
}
.chat-user {
  width: 360px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  float: left;

  .user-list {
    padding-top: 6px;
    overflow: auto;
    height: calc(100vh - 220px);
  }

  .cu-title {
    line-height: 48px;
    color: #999;
    font-size: 14px;
    padding-left: 22px;
    height: 48px;
    border-bottom: none;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background: linear-gradient(90deg, #f5fcfc, #fcfbfa);
  }

  .user-item {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 14px 12px;
    cursor: pointer;
    margin: 2px 8px;
    border-radius: 4px;

    .figure {
      float: left;
      position: relative;

      img {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        margin-right: 15px;
      }

      .notice-badge {
        position: absolute;
        top: -4px;
        left: 28px;
        text-align: center;
        height: 12px;
        font-size: 11px;
        color: #fff;
        line-height: 12px;
        display: inline-block;
        background: #fe574a;
        border-radius: 11px;
        border: 1px solid #fff;
        z-index: 1;
        min-width: 14px;
        padding: 2px;
      }
    }

    .baseName {
      float: left;
      width: 250px;
      margin-top: -2px;
      position: relative;
    }

    p {
      width: 220px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      float: left;
      margin-top:5px;

      span {
        font-size: 15px;
        font-weight: 500;
        color: #222;
      }

      label {
        margin-left: 12px;
        font-size: 13px;
        color: #333;
      }
    }
   strong{
    font-weight: 100;
   }
    em {
      display: block;
      font-style: normal;
      float: right;
      font-size: 13px;
      color: #b3b3b3;
      margin-top: 8px;
      margin-right: -8px;
    }

    .msg-tips {
      overflow: hidden;
      white-space: nowrap;
      font-size: 13px;
      color: #999;
      padding-top: 7px;
      text-overflow: ellipsis;
      clear: both;
    }
  }
  .user-item:hover{
    background: #f2f2f2;
  }
}

.chat-conversation {
  float: left;
  box-sizing: border-box;
  margin-left: 5px;
  position: relative;
  height: 100%;
  background-color: #fff;
  width: 835px;
  border-radius: 12px;

  .top-info {
    height: 48px;
    line-height: 48px;
    padding: 0 15px 0 30px;

    span {
      display: inline-block;
      font-size: 14px;
      color: #666;
      margin-right: 20px
    }

    .vline {
      width: 1px;
      height: 12px;
      vertical-align: middle;
      background: #e0e0e0;
      display: inline-block;
      margin: 0 6px;
    }

    label {
      display: block;
      float: right;
      height: 34px;
      line-height: 34px;
      background: rgba(0,190,189,.1);
      border-color: transparent;
      font-size: 13px;
      border-radius: 4px;
      margin-top:8px;
      font-weight: 400;
      padding: 0 8px;
      color: #00a6a7;
      cursor: pointer;
    }
     label:hover{
          background: rgba(0,190,189,.2);
     }
  }

  .chat-position {
    position: relative;
    width: 770px;
    background: linear-gradient(90deg, #f5fcfc, #fcfbfa);
    padding: 16px 20px;
    border-radius: 12px;
    margin: auto;
     cursor: pointer;

    span {
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      color: #222;
    }
    span:hover{
      color: #0077FF;
    }
    label {
      font-size: 20px;
      font-family: kanzhun-Regular, kanzhun;
      color: #fe574a;
      position: relative;
      top: 1px;
      display: inline-block;
      margin-left: 26px;
      margin-right: 26px;
    }
  }
}

.chat-record {
  height: 50vh;
  overflow: auto;
  .msg-item {
    min-height: 40px;
    margin-top: 18px;
    padding: 0 20px;
    clear: both;
    overflow: hidden;
  }
  .chatTime{
    text-align: center;
    color: #999;
  }
  .msg-left {
    width: 100%;
    .avator {
      width: 36px;
      height: 36px;
      border-radius: 100%;
      margin-left: 10px;
      margin-right: 20px;
    }
    .chat-pic{
      width: 320px;
      height: 200px;
    }

    p {
      line-height: 22px;
      position: relative;
      display: inline-block;
      max-width: 420px;
      padding: 8px 12px;
      vertical-align: top;
      word-break: break-all;
      border-radius: 8px;
      background-color: #f8f8f8;
      color: #333;
      font-size: 14px;
    }
  }

  .msg-right {
    width: 100%;
    text-align: right;

    .avator {
      width: 36px;
      height: 36px;
      border-radius: 100%;
      margin-left: 10px;
      margin-right: 20px;
    }
    .chat-pic{
      width: 320px;
      height: 200px;
    }
    p {
      line-height: 22px;
      display: inline-block;
      max-width: 420px;
      padding: 8px 12px;
      vertical-align: top;
      word-break: break-all;
      border-radius: 8px;
      background-color: #f8f8f8;
      color: #333;
      font-size: 14px;
    }
  }
  .exchangeWxLeft{
      display: inline-block;
      padding: 12px;
      word-break: break-all;
      vertical-align: top;
      border-radius: 8px;
      background-color: #f8f8f8;
      color: #333;
      font-size: 14px;
      margin: 10px;
      img{
        width: 27px;
        height: 27px;
        float: left;
      }
      h4{
        font-weight: 400;
        float: left;
        margin-left: 10px;
        font-size: 15px;
        margin-top: 4px;
      }
      .exchange-btn{
        overflow: hidden;
        clear: both;
        span{
          display: inline-block;
          margin-top: 10px;
          width: 125px;
          height: 33px;
          line-height: 33px;
          text-align: center;
          border-radius: 4px;
          background: #EEEEEE;
          margin-right: 10px;
          font-size: 14px;
          color: #222;
          cursor: pointer;
        }
      }
       .exchange-disabled{
           overflow: hidden;
          clear: both;
          span{
            display: inline-block;
            margin-top: 10px;
            width: 125px;
            height: 33px;
            line-height: 33px;
            text-align: center;
            border-radius: 4px;
            background: #EEEEEE;
            margin-right: 10px;
            font-size: 14px;
            color: #999;
          }
        }
        .exchange-btn span:hover{
          background: #0077FF;
          color: #fff;
        }
      .copy-box{
        margin-top: 15px;
        span{
          font-size: 15px;
          letter-spacing: 1px;
           color: #666;
        }
      }
      .copyBtn{
        clear: both;
        width:260px;
        height: 33px;
        margin-top: 10px;
        line-height: 33px;
        text-align: center;
        border-radius: 6px;
        background: rgba(0,190,189,.1);
        margin-right: 10px;
        font-size: 14px;
        color: #00a6a7;
        cursor: pointer;
      }
   
  }
  .interview-left{
      h4{
        margin-bottom: 15px;
      }
     .interviewBtn{
        clear: both;
        width:296px;
        height: 33px;
        margin-top: 10px;
        line-height: 33px;
        text-align: center;
        border-radius: 6px;
        background: rgba(0,190,189,.1);
        margin-right: 10px;
        font-size: 14px;
        color: #00a6a7;
        cursor: pointer;

      }
  }
  .exchangeWX-text{
     line-height: 22px;
      display: inline-block;
      max-width: 420px;
      padding: 8px 12px;
      word-break: break-all;
      vertical-align: top;
      border-radius: 8px;
      background-color: #f8f8f8;
      color: #333;
      font-size: 14px;
      img{
        width: 27px;
        height: 27px;
        float: left;
      }
      span{
        display: inline-block;
        margin-left:8px;
        margin-top:3px;
        font-size: 15px;
      }
  }
  .resumeName{
    margin-top: 5px;
    cursor: pointer;
    img{
      width: 25px;
      height: 30px;
      display: block;
      float: left;
    }
    label{
      display: inline-block;
      margin:5px;
      cursor: pointer;
    }
  }
}

.send-box {
  position: absolute;
  clear: both;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  .picture-uploader{
    display: inline-block;
  }
  .send-top {
    border-top: 1px solid #e6e8eb;
    padding: 16px 40px 0;
    i{
      display: inline-block;
      margin-right: 20px;
      width: 1px;
      height:21px;
      background: #e0e0e0;
    }
    img {
      margin-right: 25px;
      cursor: pointer;
    }
  }
  .toolbar-btn{
    display: inline-block;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #e6e8eb;
    padding: 2px 10px;
    line-height: 24px;
    vertical-align: top; 
    margin-right: 10px;
    color: #666;
    font-size: 14px;
    position: relative;
    bottom: 1px;
    cursor: pointer;
  }
  .send-btn {
    font-size: 14px;
    line-height: 30px;
    min-width: auto;
    margin-right: 0;
    width: 61px;
    height: 30px;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    background: #0077FF;
    float: right;
    cursor: pointer;
    margin: 10px 30px
  }
}

.emoj {
  position: absolute;
  background: #fff;
  height: 280px;
  width: 530px;
  padding-top: 18px;
  left: 0;
  top: -300px;
  border: 1px solid #f2f5fa;
  box-shadow: 0 0 14px rgba(0, 0, 0, .11);
  -webkit-box-shadow: 0 0 14px rgba(0, 0, 0, .11);
  z-index: 10;
  padding-left: 20px;
  span{
    display: inline-block;
    margin: 5px;
    cursor: pointer;
  }
}
.noMsg{
 position: relative;
    height: 100%;
    text-align: center;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

  img{
    width: 200px;
  }
  span{
    display: block;
        font-size: 13px;
    margin-top: 30px;
    color: #666;
  }
}
.interviewBox{
  p{
    margin-bottom: 15px;
    span{
      width: 72px;
      height: 20px;
      text-align: right;
      display: inline-block;
      color: #9fa3b0;
      font-size: 14px;
    }
    label{
      display: inline-block;
      margin-left: 20px;
      color: #414a60;
      font-size: 14px;
      width: 360px;
    }
  }
}
.inviteForm{
  .interviewLocation{
    width: 300px;
    height: 35px;
    line-height: 35px;
    padding-left: 15px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    cursor: pointer;
  }
  .interviewLocation:hover{
    border: 1px solid #0077FF;
    background: #f7f7f7;
  }
}
</style><style lang="scss">
.send-box {
  .el-textarea__inner {
    border: none;
    padding: 12px 30px;
    height: 60px;
    color: #000;
    outline: none;
    resize: none;
  }
}
.interviewBox{
  .el-dialog__body{
    padding:5px 20px;
  }
  .el-button--primary{
    background: #0077FF;
    border: none;
  }
  .el-dialog__footer{
    text-align: center;
  }
 .inviteForm{
  .el-input__inner{
    width: 317px;
    height: 35px;
  }
  .el-form-item{
    margin-top: 14px;
    margin-bottom: 0;
  }
 }

}
</style>
